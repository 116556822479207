


import FETCHING from '../../library/fetching';
  import UMUM from "../../library/umum.js";

export default {
  data() {
    return {

      lampiran : {
          id : '',
          direktori_id : '',
          unit_kerja_id : '',
          master_type_id : 1,
          uraian : '',
          keterangan : '',
          file : null,
          file_type : '',
          notice : '',
          status_wali : 0,
          status_opd : 0,
        },

      hasilaudit : {
          id : '',
          direktori_id : '',
          unit_kerja_id : '',
          uraian : '',
          keterangan : '',
          file : null,
          file_type : '',
        },

      tanggapan : {
          id : '',
          direktori_id : '',
          unit_kerja_id : '',
          uraian : '',
          keterangan : '',
          file : null,
          file_type : '',
        },

        form : {
          id : '',
          uraian : '',
          keterangan: '',
          daritgl: '',
          sampaitgl: '',
          instansi_id: '',
          unit_kerja_id: '',
          master_type_id: 1,
          list_opd:[],
        },


        filterku : {
          instansi_id : '',
          unit_kerja_id : '',
          direktori_id : '',
          status_wali : null,
        },

        filter_dir : '',

        tahundir : 0,
        total_file : 0,
        nama_direktori : '',
        nama_uraian : '',

        dummyObj : null,
        
      list_lampiran : [],
        list_data_direktori : [],
        list_direktori : [],
        list_unitkerja : [],
        
        list_direktori_opd:[],
        list_hasil:[],
        
        lokasi : [],

        
        // list_dir : [],

        
      file_path: this.$store.state.url.URL_APP + "uploads/",

      instansi_id : '',
      unit_kerja : '',




        mdl_add : false,
        mdl_add_tanggapan : false,
        mdl_open_tanggapan : false,
        mdl_edit_tanggapan : false,
        mdl_edit : false,
        mdl_delete : false,
        mdl_list : false,
        mdl_add_file : false,
        mdl_edit_file : false,
        mdl_view_file : false,
        mdl_keterangan :false,
        mdl_kembalikan :false,
        mdl_approve :false,
        mdl_open : false,
        mdl_unit : false,
        mdl_hasil : false,
        mdl_hasil_file : false,
        mdl_list_hasil : false,
        mdl_delete_tanggapan : false,

        cthSelect : '',

        page_first: 1,
        page_last: 0,
        page_limit : 10,
        cari_value: "",
        FETCHING : FETCHING,
        UMUM : UMUM,
        fileOld : '',


        cari_direktory: "",
        cari_unitKerja : '',

      }
    },
  methods: {


    //  getView : async function(){
    //     fetch(this.$store.state.url.URL_file + "getView", {
    //         method: "POST",
    //         headers: {
    //         "content-type": "application/json",
    //         authorization: "kikensbatara " + localStorage.token
    //         },
    //         body: JSON.stringify({
    //             data_ke: this.page_first,
    //             cari_value: this.cari_value,
    //             page_limit : this.page_limit,
    //             unit_kerja_id : this.filterku.unit_kerja_id,
    //             direktori_id : this.filterku.direktori_id,
    //             status_wali : this.filterku.status_wali,
    //         })
    //     })
    //         .then(res => res.json())
    //         .then(res_data => {
    //           console.log(res_data)
    //           this.list_data = res_data.data;
    //           this.page_last = res_data.jml_data;
    //     });
    //   },

     getView : async function(){
        this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_direktorireviu + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
                unit_kerja_id : this.form.unit_kerja_id,
                master_type_id : this.form.master_type_id,
                instansi_id : this.instansi_id,
                unit_kerja : this.unit_kerja_id,
                direktori_id : this.id_direktori,
                tipe : this.tipe
            })
        })
          .then(res => res.json())
          .then(res_data => {
              this.list_data_direktori = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },

    getHasilaudit : function(){
      fetch(this.$store.state.url.URL_filereviu + "hasilaudit", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
               
                unit_kerja : this.lampiran.unit_kerja_id,
                direktori_id : this.lampiran.direktori_id,
            })
        })
          .then(res => res.json())
          .then(res_data => {
              this.hasilaudit = res_data.data[0].file;
              console.log("sfsdf ", this.hasilaudit);
              
      });
    },


    getTanggapan : function(dir, unit){
   
        fetch(this.$store.state.url.URL_filereviu + "tanggapan", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                direktori_id : dir,
                unit_kerja_id : unit,

            })
        })
            .then(res => res.json())
            .then(res_data => {
              console.log("AAAAAAAAAAAA " + res_data.data)
              this.list_hasil = res_data.data;
        });
      },


       approveLampiran: async function(){
        // await UMUM.notifApprove();
        fetch(this.$store.state.url.URL_filereviu + 'approve', {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.lampiran)
            }).then(res_data => {
              this.getLampiran();
              this.getView1(this.form.id);
              this.Notify ('Mempublish', 'primary', 'check_circle_outline');
        });
      },

       evaluasiLampiran: async function(){
        fetch(this.$store.state.url.URL_filereviu + 'kembalikan', {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.lampiran)
            }).then(res_data => {
              this.getLampiran();
              this.getView1(this.form.id);
              this.Notify ('Mengevaluasi', 'primary', 'check_circle_outline');
        });
      },

      lokasiData : function(id){
      
          fetch(this.$store.state.url.URL_direktori + "lokasi", {
              method: "POST",
              headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
              },
              body: JSON.stringify({
                  
                  id_direktori : id
              })
          })
            .then(res => res.json())
            .then(res_data => {
                this.lokasi = res_data.data;
                
                this.$store.commit("hideLoading");
                
        });
    },

    selectData : function(data){

          this.form.id = data.id;
          this.form.uraian = data.uraian;
          this.form.keterangan = data.keterangan;
          this.form.daritgl = data.daritgl;
          this.form.sampaitgl = data.sampaitgl;
          this.form.unit_kerja_id = data.unit_kerja_id;
          this.form.master_type_id = data.master_type_id;

          this.lampiran.direktori_id = data.id;
          this.lampiran.unit_kerja_id = data.unit_kerja_id;

          this.getLampiran();

      },

      getLampiran : function( ){
        // this.$store.commit("shoWLoading");
        console.log('ambe lampiran');
        console.log(this.lampiran.direktori_id);
        console.log(this.lampiran.unit_kerja_id);
        fetch(this.$store.state.url.URL_filereviu + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                direktori_id : this.lampiran.direktori_id,
                unit_kerja_id : this.lampiran.unit_kerja_id
                

            })
        })
            .then(res => res.json())
            .then(res_data => {
              console.log(res_data)
              this.list_lampiran = res_data
        });
      },


      
      selectLampiran : function(data){
        this.lampiran.direktori_id = data.id_direktori
        this.lampiran.unit_kerja_id= data.id_instansi        
        this.lampiran.file= ''        
        this.lampiran.file_old= data.file        

        // 
        this.hasilaudit.unit_kerja_id= data.id_instansi 
        this.hasilaudit.direktori_id = data.id_direktori
        this.tanggapan.unit_kerja_id= data.id_instansi
        this.tanggapan.direktori_id = data.id_direktori
        this.tanggapan.id = data.id;
        this.tanggapan.uraian = data.uraian;
          this.tanggapan.keterangan = data.keterangan;
          this.tanggapan.file_old =  data.file
          this.tanggapan.file = this.tanggapan.file
        // 
        
        this.getLampiran() 
        this.getTanggapan(data.id_direktori, data.id_instansi) 


      },

      selectHasil : function(data){

          this.hasilaudit.id = data.id;
          this.hasilaudit.direktori_id = data.id_direktori
          this.hasilaudit.unit_kerja_id = data.unit_kerja_id;
          this.hasilaudit.uraian = data.uraian;
          this.hasilaudit.keterangan = data.keterangan;
          this.hasilaudit.file_old =  data.file
          this.hasilaudit.file = this.hasilaudit.file
      },  

      selectTanggapan : function(data){

          this.tanggapan.id = data.id;
          this.tanggapan.direktori_id = data.id_direktori
          this.tanggapan.unit_kerja_id = data.unit_kerja_id;
          this.tanggapan.uraian = data.uraian;
          this.tanggapan.keterangan = data.keterangan;
          this.tanggapan.file_old =  data.file;
          this.tanggapan.file = this.tanggapan.file
      },  

       selectFileLampiran : function(data){ 
         this.lampiran.id = data.id;
          this.lampiran.direktori_id = data.direktori_id;
          this.lampiran.unit_kerja_id = data.unit_kerja_id;
          this.lampiran.master_type_id = data.master_type_id;
          this.lampiran.uraian = data.uraian;
          this.lampiran.keterangan = data.keterangan;
          this.lampiran.status_wali = data.status_wali;
          this.lampiran.status_opd = data.status_opd;
          this.lampiran.notice = data.notice;
        this.lampiran.file_old =  data.file
        
        this.lampiran.file = this.lampiran.file
          
          this.form.unit_kerja_id = data.unit_kerja_id;

        console.log(data); 

      },

      addHasil: function(){
 
        // var get_profile = JSON.parse(localStorage.profile);
        // this.hasilaudit.unit_kerja_id = get_profile.profile.unit_kerja; 

        var formData = new FormData();
        formData.append('pbx', JSON.stringify(this.hasilaudit));
        formData.append('file', this.hasilaudit.file);

        fetch(this.$store.state.url.URL_filereviu + 'addDatax', {
            method: "POST",
            headers: {
                authorization: "kikensbatara " + localStorage.token
            },
            body: formData
            }).then(res_data => {
              
              // this.lampiran.direktori_id
               this.Notify ('Sukses Menambah Data', 'primary', 'check_circle_outline');
              var a = this.lampiran.direktori_id
              var b = this.lampiran.unit_kerja_id 
              this.getLampiran(a, b); 
              this.getView1(data.id);
        });
      },



      addTanggapan: function(){
 
        // var get_profile = JSON.parse(localStorage.profile);
        // this.hasilaudit.unit_kerja_id = get_profile.profile.unit_kerja; 

        var formData = new FormData();
        formData.append('pbx', JSON.stringify(this.tanggapan));
        formData.append('file', this.tanggapan.file);

        fetch(this.$store.state.url.URL_filereviu + 'addDatax', {
            method: "POST",
            headers: {
                authorization: "kikensbatara " + localStorage.token
            },
            body: formData
            }).then(res_data => {
              
               this.Notify ('Sukses Menambah Data', 'primary', 'check_circle_outline');
              var a = this.lampiran.direktori_id
              var b = this.lampiran.unit_kerja_id 
              this.getTanggapan(a, b); 
        });
      },
      
      editTanggapan: function(){
 
        // var get_profile = JSON.parse(localStorage.profile);
        // this.hasilaudit.unit_kerja_id = get_profile.profile.unit_kerja; 

        var formData = new FormData();
        formData.append('pbx', JSON.stringify(this.tanggapan));
        formData.append('file', this.tanggapan.file);

        fetch(this.$store.state.url.URL_filereviu + 'editDatax', {
            method: "POST",
            headers: {
                authorization: "kikensbatara " + localStorage.token
            },
            body: formData
            }).then(res_data => {
              
               this.Notify ('Sukses Menambah Data', 'primary', 'check_circle_outline');
              var a = this.lampiran.direktori_id
              var b = this.lampiran.unit_kerja_id 
              this.getTanggapan(a, b); 
        });
      },

      removeDatax : function(E){
      fetch(this.$store.state.url.URL_file + "removeDatax", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.tanggapan.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
           var a = this.lampiran.direktori_id
          var b = this.lampiran.unit_kerja_id 
          this.getTanggapan(a, b);
      });

    },

      

      


     indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },

         cari_data : function(){
            this.page_first = 1;
            this.getView();
        },


        funcAwaitDir : async function(){
          this.list_direktori = await FETCHING.postDirektori(this.filterku.unit_kerja_id, this.cari_direktory)
        },
        funcAwaitUnit : async function(){
          this.list_unitkerja = await FETCHING.getUnitKerjaAutocomplete(this.cari_unitKerja)
        },

    async getView1 (b){
      console.log(b);

      this.list_direktori_opd = await FETCHING.FETCH_POST(this.$store.state.url.URL_direktorireviu + "view2", {id : b, id_instansi : this.instansi_id, tipe : this.tipe })
     this.nama_direktori = this.list_direktori_opd[0].uraian;
     this.nama_uraian = this.list_direktori_opd[0].keterangan;
     
      this.tahundir = this.list_direktori_opd[0].tahun;
     
     
      },


    // ====================================== CONTOH eDOC ====================================
    onClickx(data){
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================

  // ====================================== CONTOH AUTOCOMPLETE ====================================
    autocomplete_db_filter : function (val, update) {
        update(() => {
          if (val === '') {}
          else {FETCHING.getContohAtocomplete(val)}
        })
    },

    dir_db_filter : function (val, update) {
        update(() => {
          if (val === '') {}
          else {FETCHING.getDir(val)}
        })
    },

    // ====================================== CONTOH AUTOCOMPLETE ====================================

    iniopd : async function(){
          this.$store.state.listOPD = await FETCHING.FETCH_POST(this.$store.state.url.URL_simpeg_instansi + "list", {cari : this.cariOPD})
        },







    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    FETCHING.getContohAtocomplete('');
    FETCHING.getDir('');
    FETCHING.getType('');


      var get_profile = JSON.parse(localStorage.profile);
      this.filterku.instansi_id = get_profile.profile.instansi_id;
      this.filterku.unit_kerja_id = get_profile.profile.unit_kerja;
      this.form.instansi_id = get_profile.profile.instansi_id;
      this.form.unit_kerja_id = get_profile.profile.unit_kerja;
      this.lampiran.unit_kerja_id = get_profile.profile.unit_kerja;
      // this.hasilaudit.unit_kerja_id = get_profile.profile.unit_kerja;
      this.instansi_id = get_profile.profile.instansi_id;
      this.unit_kerja_id = get_profile.profile.unit_kerja;
    this.tipe = get_profile.profile.trista_monev;
      this.funcAwaitUnit();
      this.funcAwaitDir();
    
      this.getView();
      
    this.iniopd();
      
      // this.getDir();
  },
}
